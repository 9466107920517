@import 'colours.scss';
@import 'typography.scss';

.error-text {
	color: red;
	@include roboto_xs;
}

@mixin boxShadow {
	box-shadow: 0px 3px 10px rgba(0,0,0,0.4);
}

@mixin bottomBorder($colour) {
	position: relative;
	overflow: hidden;

	&:before {
		content: '';
		position: absolute;
		height: 4px;
		background-color: $colour;
		bottom: 0;
		left: 0;
		width: 100%;
		transition: all .5s ease;
	}
}

@mixin bottomBorderHover($colour) {
	position: relative;
	overflow: hidden;

	&:after {
		content: '';
		position: absolute;
		height: 4px;
		background-color: $colour;
		bottom: 0;
		left: -2px;
		width: 0%;
		transition: width .5s ease;
	}

	&:hover {
		&:after {
			width: calc(100% + 4px);
		}
	}
}

@mixin shadowHover($colour) {
	transition: all ease .5s;
	box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
	background-color: $colour;

	&:hover {
		box-shadow: 0px 10px 15px 2px rgba(0, 0, 0, 0.25);
		background-color: darken($colour, 5%);
	}
}

//Buttons

button, .p-fileupload-choose {
	border: none;
	border-radius: 4px;
	cursor: pointer;
	font-size: 16px;
	outline: none;
	padding: 12px 24px;
}

button svg + span {
	padding-left: 12px;
}

button span + svg {
	padding-left: 12px;
}

.btn-primary {
	background: var(--primary-colour);
	color: #FFF;
}

.btn-primary.disabled {
	background: #E3E3E3;
	border: none;
	color: #8D8D8D;
}

.btn-secondary, .p-fileupload-choose {
	background: #FFF;
	border: 1px solid var(--primary-colour);
	color: var(--primary-colour);
}

.btn-secondary.disabled {
	border: 1px solid #E3E3E3;
	color: #8D8D8D;
}

.btn-tertiary {
	background: #FFF;
	border: none;
	color: var(--primary-colour);
	filter: saturate(300%);
}

.disabled {
	-moz-user-select: none;
	-ms-user-select: none;
	-webkit-user-select: none;
	user-select: none;
	pointer-events: none;
}

.p-button-icon-left {
	margin-right: 6px;
}

.upload-button {

	.p-button-label {
		max-width: 180px;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
}

//Calendar
.p-calendar.p-invalid.p-component > .p-inputtext {
	border-color: #e24c4c;
}

.p-datepicker {
	padding: 0.5rem;
	background: #ffffff;
	color: #383838;
	border: 1px solid #ced4da;
	border-radius: 6px;
	@include roboto_s;
}

.p-datepicker button {
	padding: 0;
}

.p-button-icon-only {
	padding: 0 1px 0 14px;
	background-color: $primary;
	color: $white;
	transition: all .5s ease;
}

.p-button-icon-only:hover {
	background-color: lighten($primary, 10%);
}

.p-datepicker-other-month {
	color: $lightGrey;
}

.p-datepicker:not(.p-datepicker-inline) {
	background: #ffffff;
	border: 0 none;
	box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}

.p-datepicker:not(.p-datepicker-inline) .p-datepicker-header {
	background: #ffffff;
}

.p-datepicker .p-datepicker-header {
	padding: 0.5rem;
	color: #495057;
	background: #ffffff;
	font-weight: 600;
	margin: 0;
	border-bottom: 1px solid #dee2e6;
	border-top-right-radius: 6px;
	border-top-left-radius: 6px;
}

.p-datepicker .p-datepicker-header .p-datepicker-prev,
.p-datepicker .p-datepicker-header .p-datepicker-next {
	width: 2rem;
	height: 2rem;
	color: #6c757d;
	border: 0 none;
	background: transparent;
	border-radius: 50%;
	transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}

.p-datepicker .p-datepicker-header .p-datepicker-prev:enabled:hover,
.p-datepicker .p-datepicker-header .p-datepicker-next:enabled:hover {
	color: #343a40;
	border-color: transparent;
	background: #e9ecef;
}

.p-datepicker .p-datepicker-header .p-datepicker-prev:focus-visible,
.p-datepicker .p-datepicker-header .p-datepicker-next:focus-visible {
	outline: 0 none;
	outline-offset: 0;
	box-shadow: 0 0 0 0.2rem #C7D2FE;
}

.p-datepicker .p-datepicker-header .p-datepicker-title {
	line-height: 2rem;
}

.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-year,
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
	color: #495057;
	transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
	font-weight: 600;
	padding: 0.5rem;
}

.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-year:enabled:hover,
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month:enabled:hover {
	color: #6366F1;
}

.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
	margin-right: 0.5rem;
}

.p-datepicker table {
	font-size: 1rem;
	margin: 0.5rem 0;
}

.p-datepicker table th {
	padding: 0.5rem;
}

.p-datepicker table th > span {
	width: 2.5rem;
	height: 2.5rem;
}

.p-datepicker table td {
	padding: 0.5rem;
}

.p-datepicker table td > span {
	width: 2.5rem;
	height: 2.5rem;
	border-radius: 50%;
	transition: box-shadow 0.2s;
	border: 1px solid transparent;
}

.p-datepicker table td > span.p-highlight {
	color: #4338CA;
	background: #EEF2FF;
}

.p-datepicker table td > span:focus {
	outline: 0 none;
	outline-offset: 0;
	box-shadow: 0 0 0 0.2rem #C7D2FE;
}

.p-datepicker table td.p-datepicker-today > span {
	background: #ced4da;
	color: #495057;
	border-color: transparent;
}

.p-datepicker table td.p-datepicker-today > span.p-highlight {
	color: #4338CA;
	background: #EEF2FF;
}

.p-datepicker .p-datepicker-buttonbar {
	padding: 1rem 0;
	border-top: 1px solid #dee2e6;
}

.p-datepicker .p-datepicker-buttonbar .p-button {
	width: auto;
}

.p-datepicker .p-timepicker {
	border-top: 1px solid #dee2e6;
	padding: 0.5rem;
}

.p-datepicker .p-timepicker button {
	width: 2rem;
	height: 2rem;
	color: #6c757d;
	border: 0 none;
	background: transparent;
	border-radius: 50%;
	transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}

.p-datepicker .p-timepicker button:enabled:hover {
	color: #343a40;
	border-color: transparent;
	background: #e9ecef;
}

.p-datepicker .p-timepicker button:focus-visible {
	outline: 0 none;
	outline-offset: 0;
	box-shadow: 0 0 0 0.2rem #C7D2FE;
}

.p-datepicker .p-timepicker button:last-child {
	margin-top: 0.2em;
}

.p-datepicker .p-timepicker span {
	font-size: 1.25rem;
}

.p-datepicker .p-timepicker > div {
	padding: 0 0.5rem;
}

.p-datepicker.p-datepicker-timeonly .p-timepicker {
	border-top: 0 none;
}

.p-datepicker .p-monthpicker {
	margin: 0.5rem 0;
}

.p-datepicker .p-monthpicker .p-monthpicker-month {
	padding: 0.5rem;
	transition: box-shadow 0.2s;
	border-radius: 6px;
}

.p-datepicker .p-monthpicker .p-monthpicker-month.p-highlight {
	color: #4338CA;
	background: #EEF2FF;
}

.p-datepicker .p-yearpicker {
	margin: 0.5rem 0;
}

.p-datepicker .p-yearpicker .p-yearpicker-year {
	padding: 0.5rem;
	transition: box-shadow 0.2s;
	border-radius: 6px;
}

.p-datepicker .p-yearpicker .p-yearpicker-year.p-highlight {
	color: #4338CA;
	background: #EEF2FF;
}

.p-datepicker.p-datepicker-multiple-month .p-datepicker-group {
	border-left: 1px solid #dee2e6;
	padding-right: 0.5rem;
	padding-left: 0.5rem;
	padding-top: 0;
	padding-bottom: 0;
}

.p-datepicker.p-datepicker-multiple-month .p-datepicker-group:first-child {
	padding-left: 0;
}

.p-datepicker.p-datepicker-multiple-month .p-datepicker-group:last-child {
	padding-right: 0;
	border-left: 0 none;
}

.p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):hover {
	background: #e9ecef;
}

.p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):focus {
	outline: 0 none;
	outline-offset: 0;
	box-shadow: 0 0 0 0.2rem #C7D2FE;
}

.p-datepicker:not(.p-disabled) .p-monthpicker .p-monthpicker-month:not(.p-disabled):not(.p-highlight):hover {
	background: #e9ecef;
}

.p-datepicker:not(.p-disabled) .p-monthpicker .p-monthpicker-month:not(.p-disabled):focus {
	outline: 0 none;
	outline-offset: 0;
	box-shadow: 0 0 0 0.2rem #C7D2FE;
}

.p-datepicker:not(.p-disabled) .p-yearpicker .p-yearpicker-year:not(.p-disabled):not(.p-highlight):hover {
	background: #e9ecef;
}

.p-datepicker:not(.p-disabled) .p-yearpicker .p-yearpicker-year:not(.p-disabled):focus {
	outline: 0 none;
	outline-offset: 0;
	box-shadow: 0 0 0 0.2rem #C7D2FE;
}

@media screen and (max-width: 769px) {
	.p-datepicker table th, .p-datepicker table td {
		padding: 0;
	}
}