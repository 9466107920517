@import '../../assets/scss/_imports.scss';

.billing {

	&-header {
		display: grid;
		grid-template-columns: 1fr repeat(4, 120px);
		align-items: center;
		padding-left: 67px;
		background: $lightGrey;
	}

	&-table-header {
		display: grid;
		grid-template-columns: 1fr repeat(4, 120px);
		align-items: center;
		padding-left: 67px;
		background: $lightGrey;
		margin-bottom: 10px;
		border-radius: 5px;
	}

	&-package {
		border: 1px solid #ccc;
		margin-bottom: 10px;
		border-radius: 5px;
		transition: all 0.3s ease;

		&-grid {
			display: grid;
			grid-template-columns: 1fr repeat(4, 120px);
			align-items: center;
			height: 64px;
			cursor: pointer;
			background: $primary;
			color: $white;
			transition: background .5s ease;
			border-radius: 5px;

			&:hover {
				background: lighten($primary, 10%);
			}

			h3 {
				margin: 0;
				color: $white;
			}
		}

		&.active {

			.billing-package-grid {
				border-bottom-left-radius: 0px;
				border-bottom-right-radius: 0px;
			}

			& .accordion-panel {
				height: auto;
			}

			& .accordion-arrow {
				transform: rotate(180deg) translateY(0px);
			}
		}
	}

	&-reseller {
		&-grid {
			padding-left: 67px;
			display: grid;
			grid-template-columns: 1fr repeat(4, 120px);
			align-items: center;

			&.pointer {
				cursor: pointer;
			}

			h4, h5 {
				margin: 0;
			}

			svg {
				transform: rotate(90deg);
				margin-right: 10px;
			}

			&.active {

				&:last-of-type {
					background: red;
				}

				svg {
					transform: rotate(180deg);
				}
			}
		}
	}

	&-customer {
		padding-left: 67px;

		&-header {
			display: grid;
			grid-template-columns: 1fr repeat(5, 120px);
			margin-left: 67px;
			margin-top: 5px;
			padding: 10px 0px 10px 20px;
			background: $lightGrey;
			border-top-left-radius: 5px;

			p {
				margin: 0;
			}
		}

		&-grid {
			padding: 10px 0px 10px 20px;
			margin-left: 67px;
			display: grid;
			grid-template-columns: 1fr repeat(5, 120px);
			align-items: center;
			background: $lightGrey;

			h5 {
				margin: 0;
			}

			p {
				margin: 0;
			}

			&:last-of-type {
				border-bottom-left-radius: 5px;
				margin-bottom: 5px;
			}
		}
	}
}

.accordion {
	&-panel {
		border-bottom-left-radius: 5px;
		height: 0px;
		border-bottom-right-radius: 5px;
		transition: background 1s ease-in-out;
		padding-bottom: 10px;
	}

	&-arrow {
		transform: rotate(90deg) translateY(1px);
		display: inline-block;
		color: $white;
		margin: 0px 20px;
	}
}

.tooltip {
	display: inline;
	margin-left: 4px;
	position:relative;

	&-icon {
		color: $primary;
		cursor: help;

		&:hover {
			.tooltip-text {
				display: block;
			}
		}
	}

	&-text {
		display: none;
		position: absolute;
		padding: 5px;
		background: $white;
		border: solid 1px $black;
		z-index: 888;
		width: 110px;
		text-align: center;
		border-radius: 5px;
		left: 50%;
		transform: translateX(-50%);
	}
}

.month-selector {
	display: grid;
	grid-template-columns: 30px 240px 30px;
	justify-items: center;
	align-items: center;

	button {
		background: transparent;
		color: $primary;

		&:disabled {
			opacity: .5;
		}
	}
}

