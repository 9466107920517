.holds-button {
	max-width: 40px;
	padding: 0;
}

.row:not(.holds-button) {
	--bs-gutter-y: 2px;
}

div.half {
	flex: 0 1 auto;
	width: 36%;
}

ul.list-none {
	margin: 0;
	padding: 0;
}