@import './colours.scss';

/* Typography */

label {
	margin-top: 0 !important;
	padding-left: 0 !important;
}

.no-margin {
	margin: 0;
}

/* Spacing */

.remove-left-padding {
	padding-left: 0 !important;

	&:first-of-type {
		padding-left: 0 !important;
	}
}

/* Inputs */

.p-dropdown-label {
	display: flex;
	align-items: center;
}

.ap-invalid-feedback {
	position: absolute;
	top: 100%;
}

.ap-invalid-icons {
	top: 50%;
	transform: translateY(-50%);
}

.p-dropdown-filter {
	padding-right: 22px;
}

.p-dropdown-filter-icon {
	right: 5px;
}

.p-dropdown-empty-message {
	padding: 0.5rem 1.25rem;
}

.p-multiselect-filter-container {
	width: auto;
	margin: 0 15px;
}

.p-multiselect-filter-icon {
	right: 8px;
}

.p-multiselect-filter-container .p-inputtext {
	padding-right: 25px;
}

/* Modals */
.p-dialog-header {
	border-bottom: solid 1px $lightGrey;
	padding-bottom: 1.5rem;
	padding-right: 100px;
}

.p-dialog-footer {
	border-top: solid 1px $lightGrey;
	padding: 1.5rem 3.75rem 0rem 3.75rem;

	@include tablet {
		padding: 1.5rem 2rem 0rem;
	}

	@include mobile {
		padding: 1.5rem 1rem 0rem;
	}

	button {
		margin-left: 8px;
	}
}

.p-dialog-content {
	margin: 0;
}

/* Data Tables */

.p-paginator-page {
	padding: 4px 6px;
	padding-bottom: 3px;
	font-size: 15px;
	transition: all .3s ease;
	margin-left: 3px;

	&:first-of-type {
		margin-left: 0px;
	}

	&:hover {
		background-color: lighten($primary, 20%);
		color: $white;
	}

	&.p-highlight {
		background-color: $primary !important;
		color: $white;
	}
}

.p-paginator-current {
	margin-right: 10px;
	cursor: default;
}

.header-center {
	div {
		justify-content: center;
	}
}