@import 'colours.scss';

$default_font_size: 16px;

$xs: calc($default_font_size / 1.25);
$s: $default_font_size;
$m: $s * 1.25;
$l: $m * 1.25;
$xl: $l * 1.3;

@mixin line_height_ratio($font_size) {
	$ratio: 1.4;
	line-height: $font_size * $ratio;
}

@mixin roboto_xs($weight: 400) {
	font-family: 'Roboto', sans_serif;
	font-size: $xs;
	font-weight: $weight;
	@include line_height_ratio($xs);
}

@mixin roboto_input($weight: 400) {
	font-family: 'Roboto', sans_serif;
	font-size: calc($s - 1px);
	font-weight: $weight;
	@include line_height_ratio($s);
}

@mixin roboto_s($weight: 400) {
	font-family: 'Roboto', sans_serif;
	font-size: $s;
	font-weight: $weight;
	@include line_height_ratio($s);
}

@mixin roboto_m($weight: 400) {
	font-family: 'Roboto', sans_serif;
	font-size: $m;
	font-weight: $weight;
	@include line_height_ratio($m);
}

@mixin roboto_l($weight: 400) {
	font-family: 'Roboto', sans_serif;
	font-size: $l;
	font-weight: $weight;
	@include line_height_ratio($l);
}

@mixin roboto_xl($weight: 400) {
	font-family: 'Roboto', sans_serif;
	font-size: $xl;
	font-weight: $weight;
	@include line_height_ratio($xl);
}

/* Targeting Element types */

h1, h2, h3, h4, h5 {
	margin: 0 0 16px 0;
	color: $primary;
}

h1 {
	@include roboto_xl(700);
}

h2 {
	@include roboto_l(500);
}

h3 {
	@include roboto_l(400);
}

h4 {
	@include roboto_m(700);
}

h5 {
	@include roboto_s(700);
}

p {
	@include roboto_s;
	margin-bottom: 16px;
}

li {
	@include roboto_s;
}

label {
	@include roboto_s(500);
}

table {
	@include roboto_s;
}

.title-top-margin {
	margin-top: 20px;
}

.p-paginator {
	@include roboto_s;
}

.p-inputtext, .p-dropdown-item, .p-multiselect-label, .p-multiselect-item, .p-dropdown-empty-message {
	@include roboto_input;
	@include roboto_xs;
}

.ap-invalid-feedback {
	@include roboto_xs;
}

/*Setting the font classes*/
.font_xs_light {
	@include roboto_xs(300);
}

.font_xs {
	@include roboto_xs;
}

.font_xs_bold {
	@include roboto_xs(500);
}

.font_xs_xbold {
	@include roboto_xs(700);
}

.font_s_light {
	@include roboto_s(300);
}

.font_s {
	@include roboto_s;
}

.font_s_bold {
	@include roboto_s(500);
}

.font_s_xbold {
	@include roboto_s(700);
}

.font_m_light {
	@include roboto_m(300);
}

.font_m {
	@include roboto_m;
}

.font_m_bold {
	@include roboto_m(500);
}

.font_m_xbold {
	@include roboto_m(700);
}

.font_l_light {
	@include roboto_l(300);
}

.font_l {
	@include roboto_l;
}

.font_l_bold {
	@include roboto_l(500);
}

.font_l_xbold {
	@include roboto_l(700);
}

.font_xl_light {
	@include roboto_xl(300);
}

.font_xl {
	@include roboto_xl;
}

.font_xl_bold {
	@include roboto_xl(500);
}

.font_xl_xbold {
	@include roboto_xl(700);
}
