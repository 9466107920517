@import '../../assets/scss/_imports.scss';

.provisioning-search, .provisioned-table, .unprovisioned-table {
	margin-bottom: 30px;
}

.table-title {
	margin-bottom: 0px;
}

.radioButtons-grid {
	display: grid;
	grid-template-columns: repeat(4, 1fr);

	input, label {
		cursor: pointer;
	}

	label {
		display: inline;
		margin-left: 8px;
		font-weight: 400;
	}
}

.checkBoxes-grid {
	display:grid;
	grid-template-columns: repeat(2, 50%);

	label {
		display: inline;
		margin-left: 8px;
	}
}

//provisioning

.provisioning-table-header, .provisioning-table-item {
	display: grid;
	grid-template-columns: 2fr 3fr 60px;

	& p {
		padding-left: 10px;
	}

	& button {
		margin-right: 10px;
		align-self: center;
	}
}

.provisioning-table-header p {
	font-weight: 600;
}

.provisioning-table-item {
	background-color: $lightGrey;
	border-radius: 5px;
	margin-bottom: 10px;
	padding: 8px 0px;

	.p-checkbox {
		align-self: center;
		justify-self: center;
	}
}

.list-table-header, .list-table-item {
	display: grid;
	grid-template-columns: 1fr 80px 60px;

	& p {
		padding-left: 10px;
	}
}

.list-table-header p {
	font-weight: 600;
}

.list-table-item {
	background-color: $lightGrey;
	border-radius: 5px;
	margin-bottom: 10px;
	padding: 8px 0px;

	.p-checkbox {
		align-self: center;
		justify-self: center;
	}
}

.datatable-action-button {
	background: none;
}

.p-with-div-nesting {
	/* Matches what the p tag defines in it's margins... */
	margin-block-start: 1em;
	margin-block-end: 1em;
	padding-left: 10px;
}

/* Pageination */
.pagination {
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	margin-top: 20px;

	p {
		margin: 0;
	}

	&-feedback {
		position: absolute;
		right: 0;
	}

	button {
		background-color: transparent;
		@include roboto_s;
		padding: 8px 16px;
		transform-origin: center;
		transition: transform .5s ease;

		svg {
			color: $primary;
		}
	}
}

.package-buttons {
	margin: 30px 0px 10px;
	display: flex;
	justify-content: flex-end;
}

.no-packages {
	color: red;
}
