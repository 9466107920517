@import "../assets/scss/_imports.scss";

.ap-alert {
	display: flex;
	margin: 24px 0 36px 0;
	padding: 24px 20px;
}

#alert-error {
	background-color: #FCC;
}

#alert-warning {
	display: flex;
	align-items: center;
	border: solid 2px #ec942c;
	border-radius: 0.5rem;
	position: relative;

	&:before {
		content: '';
		position: absolute;
		height: 100%;
		width: 66px;
		background-color: #ec942c;
		left: 0;

		@include mobile {
			height: 40px;
			width: 100%;
			top: 0;
		}
	}

	p {
		margin-left: 66px;

		@include mobile {
			margin-left: 0px;
			margin-top: 40px;
		}
	}

	svg {
		z-index: 1000;
		color: #fff;
		position: absolute;
	@include mobile  {
		top: 8px;
		left: 20px;
	}
	}
}

#alert-icon {
	font-size: 24px;
}

.alert-message {
	margin: 0;
	margin-left: 12px;
}
