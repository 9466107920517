/*Greyscale*/
$white: #fafafa;
$lightGrey: #eee;
$grey: #8c8c8c;
$darkGrey: #383838;
$black: #0d0d0d;

/*Brand*/
$primary: #143872; /*blue*/
$secondary: #f68b1f; /*orange*/


@mixin transparency($colour, $alpha: 0.5) {
	@if type-of($colour) == color and $alpha >= 0 and $alpha <= 1 {
		background-color: rgba($colour, $alpha);
	}
}

.itelBlue {
	color: $primary;
}