@import '../../assets/scss/_imports.scss';

.loading-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.8);
	display: flex;
	justify-content: center;
	align-items: center;
	color: white;
	font-size: 24px;
	z-index: 1000;
	opacity: 0;
	transition: opacity 0.3s ease-in-out;
	opacity: 1;
	pointer-events: visible;
}

.spinner {
	transform: rotateZ(45deg);
	perspective: 1000px;
	border-radius: 50%;
	width: 100px;
	height: 100px;
	color: $white;

	&:before,
	&:after {
		content: '';
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		width: inherit;
		height: inherit;
		border-radius: 50%;
		transform: rotateX(70deg);
		animation: 1s atomspin linear infinite;
	}

	&:after {
		color: $secondary;
		transform: rotateY(70deg);
		animation-delay: .4s;
	}
}

@keyframes atomspin {
	0%, 100% {
		box-shadow: .2em 0px 0 0px currentcolor;
	}

	12% {
		box-shadow: .2em .2em 0 0 currentcolor;
	}

	25% {
		box-shadow: 0 .2em 0 0px currentcolor;
	}

	37% {
		box-shadow: -.2em .2em 0 0 currentcolor;
	}

	50% {
		box-shadow: -.2em 0 0 0 currentcolor;
	}

	62% {
		box-shadow: -.2em -.2em 0 0 currentcolor;
	}

	75% {
		box-shadow: 0px -.2em 0 0 currentcolor;
	}

	87% {
		box-shadow: .2em -.2em 0 0 currentcolor;
	}
}
