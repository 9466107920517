@import "../../assets/scss/_imports.scss";

$backgroundColour: $white;
$textColour: $darkGrey;
$hoverColour: $secondary;
$imageColour: $darkGrey;

.iconCard {
	text-decoration: none;
	border-radius: 5px;
	overflow: hidden;
	padding: 20px 30px 30px;
	margin-bottom: 30px;
	@include boxShadow;
	@include bottomBorder($primary);
	color: $textColour;
	background-color: $backgroundColour;

	&_content {
		display: grid;
		grid-template-columns: auto 80px;
		grid-row-gap: 40px;
		align-items: center;

		h2 {
			margin: 0;
		}

		&_text {
			grid-column: span 2;

			p {
				margin: 0;
			}
		}

		&_image {
			justify-self: end;

			svg {
				color: $primary;
				transition: color .5s ease;
				font-size: 45px;
			}
		}
	}
}

.iconCardWithLink {
	position: relative;
	@include bottomBorderHover($hoverColour);

	&:hover {

		.iconCard_content {
			svg {
				transition-delay: .2s;
				color: $hoverColour;
			}
		}
	}
}
