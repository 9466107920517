@import "../../assets/scss/_imports.scss";

$dropDownInnerHeight: 200px;
$dropDownInnerWidth: 300px;
$dropDownPadding: 20px;
$dropDownWidth: $dropDownInnerWidth + ($dropDownPadding * 2);
$dropDownHeight: $dropDownInnerHeight + ($dropDownPadding * 2);

.topNav {
	position: fixed;
	z-index: 999;
	top: 0;
	width: 100%;
	@include boxShadow;

	&_backdrop {
		opacity: 0;
	}

	&_grid {
		height: 80px;
		background-color: $darkGrey;
		display: grid;
		padding: 0px 50px;
		grid-template-columns: 150px auto 50px;
		align-items: center;
		justify-items: center;
		position: relative;
		z-index: 998;
	}

	&_logo {
		width: 180px;
	}

	&_links {
		justify-self: start;
		margin-left: 35px;

		a {
			color: $white;
			text-decoration: none;
			padding: 0px 20px;
			height: 76px;
			display: inline-flex;
			align-items: center;
			transition: all .5s ease;
			border-bottom: solid 4px $darkGrey;

			svg {
				margin-right: 8px;
				margin-bottom: 2px;
			}

			&:hover {
				background-color: $white;
				color: $darkGrey;
				border-bottom: solid 4px $secondary;
			}
		}

		.active {
			border-bottom: solid 4px $secondary;
		}
	}

	&_icon {
		background-color: $white;
		height: 34px;
		width: 34px;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		transition: all .5s ease;
		cursor: pointer;
		border: solid 2px $white;

		&:hover, &_active {
			background-color: $darkGrey;
			color: $white;
		}

		&_image {
			position: absolute;
			transition: opacity .5s ease;
			opacity: 0;

			&_active {
				opacity: 1;
			}
		}
	}

	&_dropDown {
		width: $dropDownInnerWidth;
		background-color: $darkGrey;
		color: $white;
		border-top: none;
		position: absolute;
		z-index: 997;
		bottom: 0px;
		right: 20px;
		height: calc(($dropDownHeight - 40px) + 2px);
		z-index: 998;
		padding: $dropDownPadding;
		border-bottom-left-radius: 5px;
		transform: translateY(0px);
		transition: transform .5s ease;
		@include boxShadow;

		&_open {
			transform: translateY($dropDownHeight + 8px);
		}

		&_text {

			&_item {
				margin: 0;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				transition: all .5s ease;
			}
		}

		&_icons {
			font-size: 20px;
			position: absolute;
			bottom: 0px;
			right: 0px;
			transition: all .5s ease;
			width: calc(100% - 40px);
			padding: 0px 20px;
			height: 60px;
			border-top: 2px solid $secondary;
			display: flex;
			align-items: center;
			justify-content: flex-end;


			&_icon {
				margin-left: 10px;
				cursor: pointer;

				svg {
					margin-left: 4px;
					position: relative;
					top: 1px;
				}
			}
		}
	}

	&_dropDown_top {
		bottom: 10px;
		right: 0px;

		&.topNav_dropDown_open {
			transform: translateY($dropDownHeight + 10px);
		}
	}

	&_dropDown_side {
		bottom: calc($dropDownHeight - ($dropDownHeight * 2));
		right: calc(($dropDownWidth - ($dropDownWidth * 2)) - 8px);

		&.topNav_dropDown_open {
			transform: translateX(calc(($dropDownWidth - ($dropDownWidth * 2))) - 8px);
		}
	}
}

/* Mobile Nav */

.topNavMobile {
	position: fixed;
	z-index: 999;
	top: 0;
	width: 100%;
	@include boxShadow;

	.topNav {

		&_backdrop {
			position: fixed;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			background-color: #000000B3;
			opacity: 0;
			transition: opacity .5s ease;
			pointer-events: none;

			&_open {
				opacity: 1;
				pointer-events:auto;
			}
		}

		&_grid {
			display: flex;
		}

		&_links {
			display: grid;
			position: absolute;
			grid-template-columns: 100%;
			grid-template-rows: repeat(6, auto);
			top: 80px;
			left: 100%;
			background: $darkGrey;
			max-width: $dropDownInnerWidth;
			width: 100%;
			height: calc(100vh - 150px); //The 150px is a rough estimate of the height of the nav plus the bottom bar in the menu
			align-content: start;
			overflow-y: auto;
			transform: translateX(0%);
			transition: transform .5s ease;
			margin-left: 0px;

			&_open {
				transform: translateX(-100%);
			}

			a {
				&:hover {
					background-color: $darkGrey;
					color: $white;
					border-bottom: solid 5px $darkGrey;
				}
			}

			.active {
				background-color: $white;
				color: $darkGrey;
				border-bottom: solid 5px $secondary;
			}
		}

		&_icon {
			display: none;
		}

		&_hamburger {
			position: absolute;
			right: 50px;
			cursor: pointer;
			height: 25px;
			width: 31px;
			padding: 5px;

			& span {
				display: inline-block;
				position: absolute;
				height: 4px;
				border-radius: 2px;
				width: 30px;
				background-color: $white;
				transition: transform .5s, top .5s .5s, opacity .5s .5s;
			}

			&_barOne {
				top: 5px;
			}

			&_barTwo {
				top: 15px;
			}

			&_barThree {
				top: 25px;
			}

			&_open {
				.topNav_hamburger {
					&_barOne {
						top: 50%;
						transform: rotate(45deg);
						transition: transform .5s .5s, top .5s;
					}

					&_barTwo {
						opacity: 0;
						transition: transform .5s, top .5s .5s, opacity .5s;
					}

					&_barThree {
						top: 50%;
						transform: rotate(-45deg);
						transition: transform .5s .5s, top .5s;
					}
				}
			}
		}

		&_dropDown {
			bottom: 0;
			left: 100%;
			position: fixed;
			z-index: 999;
			max-width: $dropDownInnerWidth;
			width: 100%;
			padding: 0;
			height: 60px;
			border-radius: 0px;
			background-color: $darkGrey;
			padding-top: 10px;
			box-shadow: none;

			&_text {
				padding-top: 5px;

				&_item {
					max-width: 160px;
				padding: 2px 20px;
			}
			}

			&:before {
				display: none;
			}

			&_openMobile {
				transform: translateX(-100%);
			}
		}
	}
}
