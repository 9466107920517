button {
	border: none;
	border-radius: 4px;
	cursor: pointer;
	font-size: 16px;
	outline: none;
	padding: 12px 24px;
}

	button svg + span {
		padding-left: 12px;
	}

	button span + svg {
		padding-left: 12px;
	}

.btn-primary {
	background: var(--primary-colour);
	color: #FFF;
}

	.btn-primary.disabled {
		background: #E3E3E3;
		border: none;
		color: #8D8D8D;
	}

.btn-secondary {
	background: #FFF;
	border: 1px solid var(--primary-colour);
	color: var(--primary-colour);
}

	.btn-secondary.disabled {
		border: 1px solid #E3E3E3;
		color: #8D8D8D;
	}

.btn-tertiary {
	background: #FFF;
	border: none;
	color: var(--primary-colour);
	filter: saturate(300%);
}

.disabled {
	-moz-user-select: none;
	-ms-user-select: none;
	-webkit-user-select: none;
	user-select: none;
	pointer-events: none;
}