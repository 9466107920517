@import "./colours.scss";
@import "./typography.scss";

$mobile-width: 100%;
$tablet-width: 768px;
$desktop-width: 992px;
$lg-desktop-width: 1200px;


@mixin mobile {
	@media (max-width: #{$tablet-width - 1px}) {
		@content;
	}
}

@mixin tablet {
	@media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) {
		@content;
	}
}

@mixin desktop {
	@media (min-width: #{$desktop-width}) and (max-width: #{$lg-desktop-width - 1px}) {
		@content;
	}
}

@mixin lg-desktop {
	@media (min-width: #{$lg-desktop-width}) {
		@content;
	}
}


@mixin gutters {
	margin: auto;
	width: calc($lg-desktop-width - 20px);

	@include desktop {
		width: calc($desktop-width - 20px);
	}

	@include tablet {
		width: calc($tablet-width - 10px);
	}

	@include mobile {
		width: calc($mobile-width - 40px);
	}
}

@mixin dynamic-gutters($size) {
	margin: auto;

	@if $size < $lg-desktop-width {
		width: $size;
	}
	@else {
		width: calc($lg-desktop-width - 20px);
	}

	@include desktop {
		@if $size < $desktop-width {
			width: $size;
		}
		@else {
			width: calc($desktop-width - 20px);
		}
	}

	@include tablet {
		@if $size < $tablet-width {
			width: $size;
		}
		@else {
			width: calc($tablet-width - 20px);
		}
	}

	@include mobile {
		width: calc($mobile-width - 40px);
	}
}

.gutters {
	@include gutters;
}

.modal-gutters-s {
	@include dynamic-gutters(600px);
}

.modal-gutters-m {
	@include dynamic-gutters(900px);
}

.modal-gutters-l {
	@include dynamic-gutters(1200px);
}

.modal-gutters-full {
	@include dynamic-gutters($lg-desktop-width);
}


@mixin twoColGrid {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-auto-rows: 1fr;
	column-gap: 30px;

	@include tablet {
		grid-template-columns: 100%;
	}

	@include mobile {
		grid-template-columns: 100%;
	}
}

.twoColGrid {
	@include twoColGrid;
}


@mixin fullGrid {
	grid-column: 1 / 7;
}

@mixin halfGrid {
	grid-column: span 3;
}

@mixin abs-center {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.noScroll {
	overflow: hidden;
}

.flex-end {
	justify-self: end;
}

/* CSS Grid */

.col-span-2 {
	grid-column: span 2;
}

.col-span-3 {
	grid-column: span 3;
}


/* Rows */
/* Use these, not the disgusting ROW that comes with prime react, the PR rows create more work than they solve */

.form-row {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-column-gap: 10px;
	margin-bottom: 1.5rem;
	position: relative;

	@include mobile {
		grid-template-columns: repeat(1, 1fr);

		& input {
			margin-bottom: 10px;
		}

		& label {
			margin-bottom: 10px;
		}
	}
}

.details-row {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	align-items: center;
	grid-column-gap: 10px;
}

.package {
	display: grid;
}

.package-row {
	display: grid;
	grid-template-columns: 1.5fr repeat(5, 1fr);
	align-items: center;
	grid-column-gap: 10px;
	padding: 0px 10px;
	width: 100%;

	@include mobile {
		p {
			@include roboto_xs;
		}
	}
}

.package-row-small {
	display: grid;
	grid-template-columns: auto 30px;
	max-width: 350px;
	padding: 0px 20px;
}

.package-row-inputs {
	display: grid;
	grid-template-columns: 1.5fr repeat(5, 1fr);
	align-items: center;
	grid-column-gap: 10px;
	padding: 0px 10px;

	@include mobile {
		p {
			@include roboto_xs;
		}
	}

	input {
		width: 100%;
	}
}

.package-row-editable {
	display: grid;
	grid-template-columns: 1.5fr repeat(5, 1fr) 30px 30px;
	align-items: center;
	grid-column-gap: 10px;
	padding: 0px 10px;

	@include mobile {
		p {
			@include roboto_xs;
		}
	}
}

.grey-row {
	background: $lightGrey;
	border-radius: 5px;
	margin-bottom: 10px;
}

/* Grids for buttons at the bottom of modals */

.button-grid {
	display: grid;
	grid-column-gap: 10px;
	grid-auto-flow: column;

	@include mobile {
		grid-row-gap: 5px;
		grid-auto-flow: unset;
	}
}
