@import "../../assets/scss/_imports.scss";

$backgroundColour: $white;
$textColour: $darkGrey;
$hoverColour: $secondary;
$imageColour: $darkGrey;

.linkCard {
	text-decoration: none;
	display: grid;
	grid-template-columns: 1fr auto;
	align-items: center;
	border-radius: 5px;
	overflow: hidden;
	padding: 20px 30px 26px;
	margin-bottom: 30px;
	@include boxShadow;
	@include bottomBorder($primary);
	color: $textColour;
	background-color: $backgroundColour;
}
