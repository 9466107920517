table {
	margin-bottom: 32px !important;
}

tr.selectable td {
	cursor: pointer;
}

h2 {
	margin-bottom: 24px;
}

#confirm-item {
	font-size: 0.9em;
	margin-top: 48px;
	display: flex;
	align-items: center;
}

em.grey {
	color: grey;
}

.pt24 {
	padding-top: 24px;
}

.tmd-edit-value-text {
	color: #666;
}