@import "../../assets/scss/_imports.scss";

.modal-header {
	display: flex;
	align-items: center;
}

.modal-close-btn {
	color: var(--primary-colour);
	padding-top: 4px;
	position: absolute;
	right: 4rem;

	@include tablet {
		right: 2rem;
	}

	@include mobile {
		right: 1rem;
	}
}

.modal-back-btn {
	color: var(--primary-colour);
	padding-top: 4px;
	position: absolute;
	left: 4rem;
	bottom: 30px;
}

#uc-modal h1 {
	font-size: 30px;
	margin: 0;
	padding-left: 3.75rem;

	@include tablet {
		padding-left: 2rem;
	}

	@include mobile {
		padding-left: 1rem;
		max-width: 75%;
	}
}

#uc-modal .body {
	padding: 2em 4rem;

	@include tablet {
		padding: 1em 2rem;
	}

	@include mobile {
		padding: 1em 1rem;
	}
}

#uc-modal hr {
	border: none;
	border-top: 1px solid;
	opacity: 0.25;
}

#uc-modal.p-dialog {
	padding: 24px 0px;
}

#uc-modal.p-dialog-title {
	position: relative;
}

//prevent the page behind from scrolling

.body-no-scroll {
	overflow: hidden;
}