@import '../../assets/scss/_imports.scss';

.invoiceGrid {
	display: grid;
	width: 500px;
	grid-template-columns: 50% 50%;
}

.upload-remove-button {
	display: inline-block;
	max-width: calc(100% - 32px);
	overflow: hidden;
	text-overflow: ellipsis;
	position: relative;
	top: 3px;
}

