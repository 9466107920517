@import '../../assets/scss/_imports.scss';

#cr-container {
	padding-top: 2em;
}

#logo {
	display: block;
	margin: auto;
	width: 50%;

	.mr3pc {
		margin-right: 3%;
	}
}

#logo1.cr {
	display: block;
	margin: auto;
	padding-bottom: 0.9rem;
	padding-top: 2px;
	width: 50%;
}

#sesame-value input,
#sesame-confirm-value input {
	width: 100%;
}

ul.pw-rules {
	margin-top: 0.75rem;
}

.pw-rules li {
	line-height: 1.5;
	@include roboto_s;
}