@import "../../assets/scss/_imports.scss";

.home-cards {
	border-radius: 5px;
	overflow: hidden;
	@include shadowHover(#f3f3f3);
}

.p-card-body {
	background-color: transparent;
} /*overwrite*/