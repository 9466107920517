:root {
	--maskbg: rgba(0, 0, 0, 0.4);
	--input-font-colour: #333;
}

.p-component-overlay {
	background-color: var(--maskbg);
	transition-duration: .2s;
}

.p-component-overlay-enter {
	animation: p-component-overlay-enter-animation 150ms forwards;
}

.p-component-overlay-leave {
	animation: p-component-overlay-leave-animation 150ms forwards;
}

@keyframes p-component-overlay-enter-animation {
	from {
		background-color: rgba(0,0,0,0);
	}

	to {
		background-color: var(--maskbg);
	}
}

@keyframes p-component-overlay-leave-animation {
	from {
		background-color: var(--maskbg);
	}

	to {
		background-color: rgba(0,0,0,0);
	}
}

.p-dialog {
	background-color: white;
	border: 1px solid rgba(0,0,0,0.175);
	border-radius: 0.5rem;
	box-shadow: 0 0.5rem 1rem rgba(0,0,0,0.15);
	padding: 1.5rem;
}

	.p-dialog-header {
		display: flex;
	}

	.p-dialog-content {
		margin: 1.5rem 0;
	}

	.p-dialog-footer {
		display: flex;
		justify-content: flex-end;
		padding: 0 3.75rem 0.5rem 3.75rem;
	}

.p-inputtext {
	background: #fff;
	border: 1px solid rgb(206, 212, 218);
	border-radius: 4px;
	color: #333;
	font-size: 0.9em;
	line-height: 1.5;
	padding: 0.375rem 0.75rem;
	transition: background-color .2s,color .2s,border-color .2s,box-shadow .2s;
}

	.p-inputtext:enabled:focus,
	.p-dropdown:not(.p-disabled).p-focus,
	.p-multiselect:not(.p-disabled).p-focus {
		border-color: #86b7fe;
		box-shadow: 0 0 0 0.25rem rgba(13,110,253,.25) !important;
		outline: none;
	}

.p-checkbox {
	width: 22px;
	height: 22px
}

	.p-checkbox .p-checkbox-box {
		border: 2px solid #ced4da;
		background: #fff;
		width: 22px;
		height: 22px;
		color: #333;
		border-radius: 4px;
		transition: background-color .2s,color .2s,border-color .2s,box-shadow .2s
	}

		.p-checkbox .p-checkbox-box .p-checkbox-icon {
			transition-duration: .2s;
			color: #fff;
			font-size: 14px
		}

			.p-checkbox .p-checkbox-box .p-checkbox-icon.p-icon {
				width: 14px;
				height: 14px
			}

		.p-checkbox .p-checkbox-box.p-highlight {
			border-color: var(--primary-colour);
			background: var(--primary-colour);
		}

.p-dropdown,
.p-multiselect {
	background: #fff;
	border: 1px solid rgb(206, 212, 218);
	border-radius: 4px;
	transition: background-color .2s,color .2s,border-color .2s,box-shadow .2s;
	min-width: 0;
}

.p-dropdown-panel,
.p-multiselect-panel {
	background: #fff;
	color: #333;
	border: 0 none;
	border-radius: 4px;
	box-shadow: 0 2px 12px 0 rgba(0,0,0,.1)
}

	.p-dropdown-panel .p-dropdown-items {
		padding: 0;
	}

		.p-dropdown-panel .p-dropdown-items .p-dropdown-item,
		.p-multiselect-panel .p-multiselect-items .p-multiselect-item {
			margin: 0;
			padding: .5rem 1.25rem;
			border: 0 none;
			color: #333;
			font-size: 0.9em;
			background: rgba(0,0,0,0);
			transition: box-shadow .2s;
			border-radius: 0
		}

			.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight,
			.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
				background: #eef2ff
			}

			.p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover,
			.p-multiselect-panel .p-multiselect-items .p-multiselect-item:not(.p-highlight):not(.p-disabled):hover {
				color: #333;
				background: #e9ecef
			}

.p-dropdown .p-dropdown-label {
	border: none;
	min-width: 0;
	display: block;
	text-overflow: ellipsis;
}

.p-dropdown-trigger,
.p-multiselect-trigger {
	/* Ignore the primereact logic - "width: 3rem" causes the control to resize itself, breaking flexbox column rules for parents */
	color: #666;
	right: 8px;
	position: relative;
}

.p-multiselect-panel .p-multiselect-header {
	padding: .75rem 1.25rem;
	border-bottom: 1px solid #dee2e6;
	color: #343a40;
	background: #f8f9fa;
	margin: 0;
	border-top-right-radius: 4px;
	border-top-left-radius: 4px
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item .p-checkbox {
	margin-right: 0.5rem;
}

.p-multiselect .p-multiselect-label {
	color: #333;
	line-height: 1.5;
	padding: 0.375rem 0.75rem;
}

.p-datatable .p-datatable-thead > tr > th {
	padding: 0.8em 0.5em;
}

.p-datatable .p-sortable-column:hover .p-sortable-column-icon {
	color: #888;
}

.p-datatable .p-sortable-column .p-sortable-column-icon {
	color: transparent;
	margin-left: 0.5rem;
}

.p-datatable .p-datatable-tbody > tr > td {
	text-align: left;
	border-bottom: 1px solid #dee2e6;
	padding: 0.8em 0.5em;
}

.p-datatable.p-datatable-selectable .p-datatable-tbody > tr.p-selectable-row:not(.p-highlight):not(.p-datatable-emptymessage):hover {
	background: rgba(0,0,0,0.09);
	color: #495057;
}

.p-paginator {
	justify-content: end;
}

	.p-paginator .p-paginator-first,
	.p-paginator .p-paginator-prev,
	.p-paginator .p-paginator-next,
	.p-paginator .p-paginator-last {
		margin: 0.1em;
		min-width: 2em;
	}

	.p-paginator .p-paginator-current {
		font-size: 0.875em;
	}

	.p-paginator .p-dropdown-trigger {
		position: initial;
		width: 2em;
	}

.p-datatable-table tbody tr:not(.p-row-odd) {
	background-color: #EEE;
}

.p-card {
	align-items: center;
	cursor: pointer;
	display: flex;
	margin-bottom: 36px;
	width: 100%;
}

.p-card-header {
	height: 100%;
	min-width: 40%;
	width: 40%;
	max-width: 40%;
}

.h-0 .p-card-header {
	background: #EAF4FF;
}

.h-1 .p-card-header {
	background: #E0F4F4;
}

.h-2 .p-card-header {
	background: #EAFFF4;
}

.p-card-body {
	background: #F3F3F3;
	height: 100%;
	padding: 12px;
}

.p-card-title {
	font-size: 1.2em;
	font-weight: bold;
}

/* OTHER */

.ap-cards {
	column-gap: 36px;
	display: grid;
	grid-template-columns: 1fr 1fr;
}

.ap-col {
	display: flex;
	flex: 1 0;
	flex-wrap: wrap;
	align-items: center;
	padding-left: 1rem;
	position: relative;
}

	.ap-col:first-of-type {
		padding-left: 0.5rem !important;
	}

.ap-label {
	display: inline-block;
	line-height: 1.5;
	margin-top: 0.35rem;
}

.ap-invalid,
.ap-invalid input {
	border-color: red;
}

.ap-invalid-icon {
	border-radius: 16px;
	border: 2px solid red;
	box-sizing: border-box;
	color: red;
	font-size: 13px;
	font-weight: 800;
	height: 19px;
	position: absolute;
	text-align: center;
	right: 0.7rem;
	top: 0.6em;
	width: 19px;
}

.ap-invalid:enabled:focus,
.ap-invalid input:enabled:focus {
	border-color: red;
	box-shadow: 0 0 0 0.25rem rgba(255,0,0,.2) !important;
}

.ap-invalid-feedback {
	color: red;
	font-size: 0.8em;
	margin-top: 0.25rem;
	width: 100%;
}

/* Primereact doesn't have this... */
.contents {
	display: contents;
}