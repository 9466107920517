/*****************************************************************************************/
/* Avoid this file if possible. If you want to put something in here, it would probably be better placed somewhere in assets/scss/.. */
/*****************************************************************************************/

:root {
	/* Font */
	--font: "Roboto",Arial,sans-serif;
	--font-colour: #111;
	/* Branded colours */
	--primary-colour: #143872; /* 20, 56, 114 */
	--primary-colour-alpha: #143872aa;
	--secondary-colour: #f68b1f; /* 246, 139, 31 */
}

body {
	/*color: var(--font-colour);*/
	/*font-family: var(--font) */
	-ms-text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%;
	margin: 0;
	overflow: auto;
	padding: 0;
	margin-top: 120px;
}

input[type="checkbox"] {
	margin-right: 12px;
}

hr {
	margin: 0 !important;
}

.buttons-r {
	text-align: end;
}

button {
	font-size: 1.2em;
}

/* This is causing issues as well. I'm taking it out for now. 
div > button + button { 
	margin-left: 12px;
}
*/

textarea {
	font-family: var(--font) !important;
}

svg {
	shape-rendering: geometricPrecision;
}

main {
	margin: 40px 0px;
}

.small {
	font-size: 0.875em;
}

.icon-btn { /* This is going to cause trouble, it already stopped me from easily changing the trash bin size. Need to rethink this. */
	background: none;
	border: none;
	font-size: 24px;
	outline: none;
	padding: 0;
}

.primary {
	color: var(--primary-colour);
}

.red {
	color: #D33;
}
